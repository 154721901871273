<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$router.go(-1)" />
    </template>
    <template #default>
      <span>
        {{ $fanyi("提现") }}
      </span>
    </template>
    <!--提现页面 -->
  </headBackBar>
  <Dialog ref="dialogRef" />
  <!-- 提现弹窗 -->
  <Dialog ref="caozuoRef">
    <div class="caozuoBox">
      <h1>{{ $fanyi("请确认以下信息是否正确!") }}</h1>
      <ul>
        <li>
          <label>{{ $fanyi("出金金額") }}:</label>
          <span>{{ form.client_amount }}</span>
        </li>
        <li>
          <label>{{ $fanyi("銀行名") }}:</label>
          <span>{{ form.client_bank_name }}</span>
        </li>
        <li>
          <label>{{ $fanyi("支店番号") }}:</label>
          <span>{{ form.client_bank_branch_no }}</span>
        </li>
        <li>
          <label>{{ $fanyi("支店名") }}:</label>
          <span>{{ form.client_bank_branch_name }}</span>
        </li>
        <li>
          <label>{{ $fanyi("預金種目") }}:</label>
          <span>{{ form.client_pay_type }}</span>
        </li>
        <li>
          <label>{{ $fanyi("收款户号") }}:</label>
          <span>{{ form.client_account }}</span>
        </li>
        <li>
          <label>{{ $fanyi("口座名義") }}:</label>
          <span>{{ form.client_bank_username }}</span>
        </li>
        <li>
          <label>{{ $fanyi("提现原因") }}:</label>
          <span>{{ form.client_reason }}</span>
        </li>
      </ul>
    </div>
  </Dialog>
  <!-- 内容区域 -->
  <div class="Content">
    <!-- 当前账余额 -->
    <div class="balance">
      <div class="title">{{ $fanyi("当前账户余额") }}</div>
      <div class="price">
        {{ $fun.JPYNumSegmentation($store.state.userInfo.balance_availability)
        }}<span>{{ $fanyi("円") }}</span>
      </div>
    </div>
    <!-- 表单区域 -->
    <div class="form">
      <van-form>
        <van-cell-group inset>
          <van-field
            required
            v-model.trim="form.client_amount"
            :label="$fanyi('出金金額')"
            @blur="validator('client_amount')"
          />
          <van-field
            required
            v-model.trim="form.client_bank_name"
            :label="$fanyi('銀行名')"
            @blur="validator('client_bank_name')"
          />
          <van-field
            required
            v-model.trim="form.client_bank_branch_no"
            :label="$fanyi('支店番号')"
            @blur="validator('client_bank_branch_no')"
          />
          <van-field
            required
            v-model.trim="form.client_bank_branch_name"
            :label="$fanyi('支店名')"
            @blur="validator('client_bank_branch_name')"
          />
          <van-field
            required
            v-model.trim="form.client_pay_type"
            :label="$fanyi('預金種目')"
            @blur="validator('client_pay_type')"
          />
          <van-field
            required
            v-model.trim="form.client_account"
            :label="$fanyi('收款户号')"
            @blur="validator('client_account')"
          />
          <van-field
            required
            v-model.trim="form.client_bank_username"
            :label="$fanyi('口座名義')"
            @blur="validator('client_bank_username')"
          />
          <van-field
            class="why"
            required
            @blur="validator('client_reason')"
            v-model.trim="form.client_reason"
            :label="$fanyi('提现原因')"
            type="textarea"
          />
        </van-cell-group>
      </van-form>
    </div>
    <div class="tixian" @click.stop="Withdraw">{{ $fanyi("申请提现") }}</div>
  </div>
</template>
<script setup>
import Dialog from "@/components/Dialog/index.vue";
import headBackBar from "@/components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();

const form = ref({
  client_amount: "", // 提现金额
  client_bank_name: "", //银行名
  client_bank_branch_no: "", //支店番号
  client_bank_branch_name: "", //支店名
  client_pay_type: "", //預金種目
  client_account: "", //口座番号
  client_bank_username: "", //口座名義
  client_reason: "", //提现申请原因
});
const validator = (type) => {
  let arr = [
    {
      key: "client_amount",
      text: proxy.$fanyi("请输入提现金额"),
    },
    {
      key: "client_bank_name",
      text: proxy.$fanyi("请输入银行账户"),
    },
    {
      key: "client_bank_branch_no",
      text: proxy.$fanyi("请输入支店番号"),
    },
    {
      key: "client_bank_branch_name",
      text: proxy.$fanyi("请输入支店名"),
    },
    {
      key: "client_pay_type",
      text: proxy.$fanyi("请输入預金種目"),
    },
    {
      key: "client_account",
      text: proxy.$fanyi("请输入口座番号"),
    },
    {
      key: "client_bank_username",
      text: proxy.$fanyi("请输入口座名義"),
    },
    {
      key: "client_reason",
      text: proxy.$fanyi("请输入提现申请原因"),
    },
  ];
  let jieGuo = arr.find((item) => {
    return item.key == type;
  });
  if (jieGuo && !form.value.client_amount) {
    proxy.$toast(proxy.$fanyi(jieGuo.text));
  }
};
// 申请提现
const Withdraw = () => {
  //  进行判断账户
  if (
    form.value.client_amount == "" ||
    form.value.client_bank_name == "" ||
    form.value.client_bank_branch_no == "" ||
    form.value.client_bank_branch_name == "" ||
    form.value.client_pay_type == "" ||
    form.value.client_account == "" ||
    form.value.client_bank_username == "" ||
    form.value.client_reason == ""
  ) {
    proxy.$toast.fail(proxy.$fanyi("请填写必填信息"));
    return;
  }
  proxy.$refs.caozuoRef.open({
    successBtn: proxy.$fanyi("确认"),
    cancelBtn: proxy.$fanyi("取消"),
    successMethods: () => {
      proxy.$toast.loading({
        duration: 0,
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      proxy.$api.capitalCashOutSend(form.value).then((res) => {
        if (res.code != 0) {
          proxy.$toast.fail(proxy.$fanyi(res.msg));
          return;
        }
        proxy.$toast.clear();
        proxy.$refs.dialogRef.open({
          title: "出金依頼が正常に発行されました",
          text: "最長1〜2営業日以内に資金が自動的にアカウントに振り込まれます",
          successBtn: proxy.$fanyi("确认"),
          befoCloseMethods: () => {
            proxy.$fun.routerToPage("/user/purse");
          },
        });
      });
    },
  });
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.caozuoBox {
  padding: 0 20px;
  h1 {
    font-size: 28px;
    color: #b4272b;
    margin-bottom: 14px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
      width: 80%;
      padding: 10px 0;
      display: flex;
      * {
        font-size: 24px;
        line-height: 1.5;
        display: block;
      }
      label {
        width: 140px;
        white-space: nowrap;
        text-align: right;
        margin-right: 10px;
      }
      span {
        flex: 1;
        max-height: 200px;
        overflow: auto;
      }
    }
  }
}
.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

:deep().van-icon-arrow {
  font-size: 28px;
}

:deep() .van-cell {
  padding: 0;
  min-height: 70px;
  margin-bottom: 15px;
  .van-cell__title {
    width: 205px;
  }
}

:deep() .van-cell-group {
  margin: 0;
}

:deep().van-field__label {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  margin-right: 0;
}

:deep() .van-field__body {
  input {
    font-size: 28px;
    flex: 1;
    height: 70px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
  }

  textarea {
    width: 460px;
    height: 138px;
    padding-top: 18px;
    padding-left: 18px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
  }
}

:deep() .van-cell:after {
  border-bottom: none !important;
}

.Content {
  width: 750px;
  height: 1400px;
  background: #f6f6f6;
  background: #f6f6f6;
  padding: 0 30px;
  padding-top: 20px;

  .balance {
    width: 690px;
    height: 224px;
    background: #ffffff;
    padding: 60px 0;
    border-radius: 6px;

    .title {
      width: 100%;
      font-size: 24px;

      font-weight: 400;
      color: #999999;
      display: flex;
      justify-content: center;
    }

    .price {
      width: 100%;
      margin: auto;
      margin-top: 29px;
      font-size: 56px;

      font-weight: 600;
      color: #b4272b;
      display: flex;
      justify-content: center;

      span {
        font-size: 24px;

        font-weight: 400;
        color: #b4272b;
        padding-top: 30px;
      }
    }
  }

  // 表单区域
  .form {
    margin-top: 15px;
    width: 690px;
    background: #ffffff;
    border-radius: 6px;
    padding: 60px 30px;
  }

  :deep() .why {
    .van-cell__title {
      height: 70px;
    }
    label {
      // padding-left: 15px;
    }
    .van-field__body textarea {
      width: 100%;
    }
  }

  .tixian {
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;

    font-weight: 400;
    color: #ffffff;
    bottom: 50px;
  }
}
</style>
